export const baseUrl = "https://back.debt.dev.menwer.net";

export const countriesURL = "countries/";

export const jobsURL = "get_jobs/";

export const getJobsURL = "/api/jobs/";

export const currenciesURL = "currencies/";

export const createEmployeeURL = "create_employee/";

export const createOfficeURL = "create_offices/";

export const createjobURL = "add_job/";

export const getEmployeesURL = "get_employees/";

export const getEmployeeServiceList = "employees_list/";

export const getListEmployeesWithoutGroupURL = "/get-employees-without-group/";

export const addGroupURL = "/api/groups/create/";

export const gitListGroupWithEmployeeURL = "/list_groups-with_employees/";

export const removeUserFromGroupURL = "/api/groups/unassign-employees/";

export const assignUserToGroupURL = "/api/groups/assign-employees/";

// matter
export const createMatterTypeURL = "add_matters_type/";

export const getMatterTypeURL = "get_matters_types";

export const getMatterStatusURL = "get_matters_statues";

export const createMatterStatusURL = "add_matters_statues/";

export const createSessionURL = "add_sessions/";

export const getSessionURL = "get_sessions";
// here
export const createCourtsURL = "create_session/";

export const getCourtsURL = "get_courts";

export const createGovernmentalURL = "add_governmental_departments/";

export const getGovernmentalURL = "get_governmental_departments";

export const createAttributesURL = "add_attributes/";

export const createSubAttributesURL = "add_sub_attributes/";

export const getSubAttributesURL = "list_attributes_with_subattributes";

export const createProceduresURL = "add_procedures/";

export const createSubProceduresURL = "add_sub_procedures/";

export const getSubProceduresWithSubURL = "list_procedures_with_subprocedures";

export const createExpenseURL = "add_expense/";

export const getExpenseURL = "get_Expenses";

export const createMatterURL = "/create_matter/";

export const getMattersURL = "/matterlist";
//
export const getClientsURL = "/api/debt_collection/clients/";

export const getClientsListURL = "/api/debt_collection/clients/";

export const getOpponentsURL = "/get_opponents/";

export const getAdministrativeEmployeesURL = "get_administrative_employees";

export const getLawyersURL = "get_lawyers";

export const getConsultantsURL = "/get_consultants";

export const getSubAttIntinalURL = "get_sub_attributes_intinal";

export const getSubAttAppealURL = "get_sub_attributes_appeal";

export const getSubAttDiscriminationURL = "get_sub_attributes_discrimination";

export const createOpponentURL = "create_opponent/";

export const createRoleURL = "/create_role/";

export const createClientURL = "create_client/";

export const createDeptClientURL = "api/debt_collection/clients/create";

export const getContractMatterURL = "get_contract_by_matter";

//attachment

export const attachmentTypeURL = "attachment_type_list";

export const attachmentListURL = "attachments_list";

export const uploadAttachmentURL = "attachments_upload/";

export const attachmentsURL = "attachments";

export const filterAttachmentsURL = "filter_attachments/";

export const courtsSessionURL = "list_sessions";

export const createCourtsSessionURL = "create_session/";

export const filterCourtsSessionURL = "court_sessions_filter/";

export const deleteSessionService = "court_sessions/";

export const update_session = "update_session";

export const updateDelegtionNoteURL = "/update_delegation_note/";

export const getInvoiceURL = "/get_invoice/";

export const getExpensesURL = "/get_expenses_for_matter";

export const createInvoiceURL = "/create_invoice/";

export const createExpenseMatterURL = "/create_expense_matter/";

export const getInvoicesURL = "/get_invoices";

export const createPaymentURL = "/create_payment/";

export const getInvoicesForMatterURL = "/get_invoices_for_matter";

export const getMatterURL = "/get_matter";

export const createContractURL = "/create_contract/";

export const getAccountingBranchURL = "/choices/accounting-branch/";

export const getCardsURL = "/choices/id_card/";

export const addInvoiceURL = "/create_invoice/";

export const createStatementsURL = "/create_statements/";

export const createPaymentReceiptURL = "/create_payment_receipt/";

export const deleteClientURL = "/delete_client";

export const updateClientURL = "/update_client";

export const getClientURL = "/api/get_client";

export const getQuickRegistration = "/quick_registration/";

export const court_sessions = "/court_sessions";

export const update_procedure_date = "/update_procedure_date";

export const update_procedure_statement = "/update_procedure_statement";

export const update_assigned_employee = "/update_assigned_employee";

// Dept Collections

export const debt_collection = "/api/debt_collection";

export const uploadDeptCollection = "/upload-opponents";

export const getDetails = "/show";

export const updateDeptCollection = "/update";

export const deleteAttachment = "/delete";

export const showContract = "/contract/show";

export const updateContract = "/contract/update";

export const getGroups = "/get_groups";

export const getEmployeeSideBar = "/employee_side_bar";

export const filterMatterURL = "/matterlist_filter/";

export const vacationRequestsURL = "/vacation-requests/";

export const employees = "/employees";

export const clientsFilterURL = "/clientslist_filter/";

export const addHoursURL = "/add_hours/";

export const getHoursURL = "/get_hours";

export const getTemplatesListURL = "/templates_list";

export const getTemplatesClientListURL = "client_templates_list";

export const getTemplatesCourtSessionsListURL = "court_session_templates_list";

export const gettemplateFieldsURL = "/template_fields";

export const gettemplateClientFieldsURL = "/client_template_fields";

export const gettemplateCourtSessionsFieldsURL =
  "/court_session_template_fields";

export const createTemplateURL = "/create_template/";

export const createClientTemplateURL = "/create_client_template/";

export const createCourtSessionTemplateURL = "/create_court_session_template/";

export const createReportCourtSessionsURL = "/create_report_court_sessions/";

export const exportStatementsURL = "/export_statements/";

export const createReportURL = "/create_report_matters/";

export const create_report_court_sessions = "/create_report_court_sessions/";

export const createReportClientURL = "/create_report_clients/";

export const resetPasswordURL = "/set_password/";

export const getStatistics = "/analytic";

export const createFilterInvoivces = "/invoices_filter/";

export const list_procedures_with_subprocedures =
  "/list_procedures_with_subprocedures";

export const getAllCourtSessionsURL = "get_all_court_sessions";

export const getClientByPhoneURL = "/get_client_by_phone_number/";

export const getImportExportsURL = "get_imports_exports_total";

export const getFieldsChoices = "get-field-choices";

export const getPaymentReceiptsListURL = "payment_receipts_list";

export const getGeneralExpensesURL = "/general-expenses/";

export const createGeneralExpensesURL = "/general-expenses/create/";
