import React, { useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import DeliveryDisclosureModal from "../modal/DeliveryDisclosureModal";

import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

const flexBoxColumnStyle = {
  display: "flex",
  flexDirection: "column",
};

const blackTextStyle = {
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "28px",
  color: "rgba(47, 43, 44, 1)",
};

const flexBoxStyle = {
  display: "flex",
  // alignItems: "center",
  pb: "10px",
  flexDirection: "column",
};
//___________________________________________________________
export default function SummaryCustodyAccordion() {
  const { summary } = useSelector((state) => state.deptCollection);

  const [expanded, setIsExpanded] = useState(true);

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleClose = () => {
    setOpenAddModal(null);
  };

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          onClick={() => {
            setIsExpanded(!expanded);
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          الملخص
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**   */}
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={flexBoxColumnStyle}>
                <Box sx={flexBoxStyle}>
                  <Typography sx={blackTextStyle}>رصيد أول الفترة:</Typography>

                  <Typography
                    sx={{
                      ...blackTextStyle,
                      fontWeight: 400,
                      lineHeight: "24px",
                      pl: "4px",
                    }}
                  >
                    {summary?.beginning_balance}
                  </Typography>
                </Box>

                <Box sx={flexBoxStyle}>
                  <Typography sx={blackTextStyle}>إجمالي المدين:</Typography>

                  <Typography
                    sx={{
                      ...blackTextStyle,
                      fontWeight: 400,
                      lineHeight: "24px",
                      pl: "4px",
                    }}
                  >
                    {summary?.total_debtor}
                  </Typography>
                </Box>

                <Box sx={flexBoxStyle}>
                  <Typography sx={blackTextStyle}>إجمالي الدائن:</Typography>

                  <Typography
                    sx={{
                      ...blackTextStyle,
                      fontWeight: 400,
                      lineHeight: "24px",
                      pl: "4px",
                    }}
                  >
                    {summary?.total_creditor}
                  </Typography>
                </Box>

                <Box sx={flexBoxStyle}>
                  <Typography sx={blackTextStyle}> رصيد اخر الفترة:</Typography>

                  <Typography
                    sx={{
                      ...blackTextStyle,
                      fontWeight: 400,
                      lineHeight: "24px",
                      pl: "4px",
                    }}
                  >
                    {summary?.ending_balance}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomizedAccordion>

      {openAddModal && (
        <DeliveryDisclosureModal
          open={openAddModal}
          onCloseModel={handleClose}
        />
      )}
    </>
  );
}
