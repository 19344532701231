// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dash: icon("dash"),
  check: icon("check-square-broken"),
  file: icon("file-attach-01"),
  coins: icon("coins-rotate"),
  folder: icon("folder-open-03"),
  user: icon("user-profile-01"),
  settings: icon("settings"),
  vacationIcon: icon("users-profiles-01"),
};

const navConfig = [
  // Admin
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "لوحة التحكم",
        icon: ICONS.dash,
        path: "/dashboard/overview",
        index: "1",
      },



      {
        title: "المهام والإجراءات",
        icon: ICONS.check,
        path: "/dashboard/collection/tasks-procedures",
        index: "2",
      },
      {
        title: " بحث عن خصم",
        icon: ICONS.dash,
        path: "/dashboard/search-oppontnet",
        index: "3",
      },

      //collection
      {
        title: "العملاء",
        icon: ICONS.file,
        path: "/dashboard/collection",
        index: "3",
      },

      //collection -finance
      {
        title: "مالي ",
        icon: ICONS.coins,
        path: "/dashboard/finance",
        index: "4",
        children: [
          {
            title: "قائمة الكشوفات",
            path: "/dashboard/finance",
          },
          {
            title: "عهدة المصروفات",
            path: "/dashboard/finance/custody-expenses",
          },
          {
            title: "أنواع المصاريف",
            path: "/dashboard/finance/expenses-types",
          },
          {
            title: "الحسابات",
            path: "/dashboard/finance/accounts",
          },
        ],
      },





      // hr
      {
        title: "الموارد البشرية",
        icon: ICONS.vacationIcon,
        path: "/dashboard/hr",
        index: "5",
        children: [
          { title: "الموظفين", path: "/dashboard/users" },


        ],
      },

      {
        title: "التهيئة الإبتدائيّة",
        icon: ICONS.settings,
        path: "/setup-wizard",
        index: "6",
      },

    ],
  },
];

export default navConfig;
