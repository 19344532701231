import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Backdrop,
  Alert,
  MenuItem,
  Button,
} from "@mui/material";

import FormProvider, { RHFTextField } from "../../../../components/hook-form";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";

import FormControl from "@mui/material/FormControl";

import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@material-ui/core/ListItemIcon";

import Select from "@mui/material/Select";

import Checkbox from "@mui/material/Checkbox";

import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

import {
  createGroupService,
  getGroupService,
  removeUserFromGroupService,
} from "../../../../redux/services/users_service";

import { toast } from "react-toastify";
import { deleteExpense, getExpensesService } from "../../../../redux/services/debt_collection_service";

// -----------------------------------------------------------------------------

// ----------- Styles --------------

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: { xs: "95%", sm: "543px", md: "800px", lg: "548px" },
  bgcolor: "#CCDDE4",
  pt: { xs: "60px", md: "24px", lg: "30px" },
  pb: { xs: "40px", md: "24px", lg: "30px" },
  px: { xs: "10px", sm: "20px", md: "20px", lg: "30px" },
  height: "auto",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& .MuiFormLabel-root": {
      color: "#BCBCBC",
      fontSize: "16px",
      boxShadow: "#101828",
    },
    "& .MuiOutlinedInput-root": {
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "#101828",
      height: "44px",
      border: "1px solid #D0D5DD",
      "&:hover fieldset": {
        border: "1px solid #D0D5DD",
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 900,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const txtFieldBorderStyle = {
  width: "100%",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  paddingBottom: "4px",
};

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const boxOfModalStyle = { position: "absolute", top: "22px", left: "22px" };

//_______________________________________________________________

export default function DeleteExpenseTypeModal({ open, onCloseModel, id }) {
  const { isLoadingDeleteEx} = useSelector(
    (state) => state.deptCollection
  );
  const dispatch = useDispatch();

  const defaultValues = {
    name: "",
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {
    dispatch(deleteExpense({ id })).then((action) => {
      if (deleteExpense?.fulfilled?.match(action)) {
        toast("تم حذف النوع بنجاح");
        onCloseModel();
        dispatch(getExpensesService({ page: 1 }));
      }
    });
  };

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ ...ModalSx }}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}} />
          </Box>
          <Typography sx={modalTitleSx}>حذف نوع مصروف</Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Typography sx={{ ...lableSx, mt: "10px" }}>
              {" "}
              هل أنت متأكد من حذف النوع
            </Typography>

            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoadingDeleteEx}
              >
                حفظ
              </LoadingButton>

              <Button
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
