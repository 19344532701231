import React, { useState } from "react";

import PropTypes from "prop-types";
// @ Mui
import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  MenuItem,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover/MenuPopover";

// ------------------------------------------------------------------------

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));

//_______________________________________________________
CustudtExpenseListTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function CustudtExpenseListTableRow(props) {
  const { row, selected, onSelectRow } = props;
  const { employees } = useSelector((state) => state.users);
  const [openPopover, setOpenPopover] = useState(null);

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const maxLength = 30;
  const statement = row?.statement || "";

  const isLongStatement = statement.length > maxLength;
  const displayText = isLongStatement
    ? `${statement.substring(0, maxLength)}...`
    : statement;
  const selectedEm = employees?.find((o) => o.id === row?.employee);

  // -------- JSX Code -------
  return (
    <>
      <StyledTableRow hover selected={selected}>
        <TableCell sx={innerCellBorderStyle}></TableCell>

        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.id}</Typography>
          </Stack>
        </TableCell>

        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            {isLongStatement ? (
              <Tooltip title={statement} placement="top">
                <Typography sx={{ ...cellTextSx }}>{displayText}</Typography>
              </Tooltip>
            ) : (
              <Typography sx={{ ...cellTextSx }}>{displayText}</Typography>
            )}
          </Stack>
        </TableCell>

        <TableCell sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>
              {row?.movement_date?.split("T")[0]}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>
              {row?.employee_obj?.first_name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.debtor}</Typography>
          </Stack>
        </TableCell>

        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.creditor}</Typography>
          </Stack>
        </TableCell>
        <TableCell align="center" sx={innerCellBorderStyle}>
          <Stack alignItems="center" justifyContent="center">
            <Typography sx={{ ...cellTextSx }}>{row?.balance}</Typography>
          </Stack>
        </TableCell>
      </StyledTableRow>
    </>
  );
}
