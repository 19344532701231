import React from "react";

import { Box, Modal, Typography, Backdrop, Button } from "@mui/material";

import { LoadingButton } from "@mui/lab";

//_______________________________________________________________

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: { xs: "95%", sm: "543px", md: "800px", lg: "548px" },
  bgcolor: "#CCDDE4",
  pt: { xs: "60px", md: "24px", lg: "30px" },
  pb: { xs: "40px", md: "24px", lg: "30px" },
  px: { xs: "10px", sm: "20px", md: "20px", lg: "30px" },
  height: "auto",
  maxHeight: "530px",
  overflow: "auto",
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 600,
  color: "rgba(37, 42, 49, 1)",
  fontSize: "18px",
  lineHeight: "36px",
  pb: "10px",
};

const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  textAlign: "center",
  fontSize: "16px",
  fontWeight: 400,
  color: "rgba(79, 94, 113, 1)",
  paddingBottom: "10px",
};

const boxOfModalStyle = { position: "absolute", top: "22px", left: "22px" };

//_______________________________________________________________

export default function DeliveryDisclosureModal({ open, onCloseModel }) {
  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ ...ModalSx }}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}} />
          </Box>

          <Box sx={{ display: "flex", width: "100%", mt: "10px", mb: "10px" }}>
            <img src="/assets/icons/qa.svg" alt="" style={{ margin: "auto" }} />
          </Box>

          <Typography sx={modalTitleSx}>
            هل أنت متأكد من تسليم الكشف؟
          </Typography>

          <Typography sx={{ ...lableSx }}>
            لن تتمكن من تعديل الكشف بعد الآن وسيتم إعلام المحاسب بجهوزية الكشف
          </Typography>

          <Box sx={boxOfActionButtonStyle}>
            <LoadingButton
              fullWidth
              disableRipple
              size="large"
              type="submit"
              variant="Contained"
              sx={loadingButtonStyle}
              //loading={isLoadingCreate}
            >
              نعم
            </LoadingButton>
            <Button
              fullWidth
              disableRipple
              size="large"
              type="submit"
              variant="Contained"
              sx={buttonStyle}
              onClick={onCloseModel}
            >
              إلغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
