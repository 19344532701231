import React from "react";
import {
  Box,
  Modal,
  Typography,
  Backdrop,
  Alert,
  Button,
  MenuItem,
  Grid,
} from "@mui/material";

import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "../../../../components/hook-form";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  createPayment,
  getOpponentsDetails,
  getPaymentsService,
} from "../../../../redux/services/debt_collection_service";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// -----------------------------------------------------------------------------

// ----------- Styles --------------

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: { xs: "95%", sm: "543px", md: "800px", lg: "548px" },
  bgcolor: "#CCDDE4",
  pt: { xs: "60px", md: "24px", lg: "30px" },
  pb: { xs: "40px", md: "24px", lg: "30px" },
  px: { xs: "10px", sm: "20px", md: "20px", lg: "30px" },
  height: "auto",
};

const txtFieldBorderStyle = {
  width: "100%",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  paddingBottom: "4px",
};

const transparentBorderTxtField = {
  width: "35%",
  "& .MuiOutlinedInput-input": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2F2B2C",
  },
  "& .MuiInputLabel-root": {
    color: "#2F2B2C",
    fontWeight: 400,
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
    borderColor: "transparent!important",
    "& fieldset": {
      borderColor: "transparent!important",
    },
    "&:hover fieldset": {
      borderColor: "transparent!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent!important",
    },
  },
};

const boxOfModalStyle = { position: "absolute", top: "22px", left: "22px" };

const groupType = [{ id: "0", name: "" }];
//_______________________________________________________________

export default function AddNewPaymentModal({ open, onCloseModel }) {
  const AddNewPaymentScheema = Yup.object().shape({});
  const dispatch = useDispatch();
  const defaultValues = {
    payment_date: dayjs(),
  };

  const { isLoadingCrePay, opponentId } = useSelector(
    (state) => state.deptCollection
  );

  const methods = useForm({
    resolver: yupResolver(AddNewPaymentScheema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    console.log("data", data);

    let dataToSend = {
      ...data,
      opponent: opponentId,
      payment_date: data.payment_date.format("YYYY-MM-DD"),
    };
    dispatch(createPayment({ data: dataToSend })).then((response) => {
      if (response?.payload?.status === 201) {
        onCloseModel();
        toast.success("  تمت عملية الإضافة بنجاح ")
        dispatch(getPaymentsService({ id: opponentId, page: 1 }));
        dispatch(getOpponentsDetails({ id: opponentId }));
      } else {
        response?.payload?.errors?.map((error, index) => (
          toast.warn(error)
        ))
      }
    });
  };

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{ ...ModalSx }}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}} />
          </Box>

          <Typography sx={modalTitleSx}>إضافة سداد</Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Grid container columnSpacing={3} mt="30px">
              <Grid item xs={6}>
                <Typography sx={lableSx}>قيمة السداد</Typography>

                <RHFTextField
                  name="value"
                  control={control}
                  placeholder={"أدخل قيمة المصروف"}
                  InputProps={{
                    endAdornment: (
                      <RHFSelect
                        name="num"
                        sx={{
                          ...transparentBorderTxtField,
                          //width: "80px",
                        }}
                        SelectProps={{
                          native: false,
                          IconComponent: () => (
                            <img src="/assets/icons/Icon.svg" alt="" />
                          ),
                        }}
                        onChange={(e) => {
                          setValue("num", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                      >
                        <MenuItem value="KY">KD</MenuItem>
                        <MenuItem value="KY">AED</MenuItem>
                        <MenuItem value="SY">SY</MenuItem>
                      </RHFSelect>
                    ),
                  }}
                  sx={{ ...txtFieldBorderStyle }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography sx={lableSx}>نوع السداد</Typography>
                <RHFSelect
                  name="type"
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "' اختر نوع السداد'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img src="/assets/icons/Icon.svg" alt="" />
                    ),
                  }}
                  onChange={(e) => {
                    setValue("type", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <MenuItem value="دفعة">دفعة</MenuItem>
                  <MenuItem value="تسوية">تسوية</MenuItem>
                </RHFSelect>
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} mt="30px">
              <Grid item xs={6}>
                <Typography sx={lableSx}>طريقة السداد</Typography>
                <RHFSelect
                  name="method"
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "' اختر نوع السداد'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img src="/assets/icons/Icon.svg" alt="" />
                    ),
                  }}
                  onChange={(e) => {
                    setValue("method", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <MenuItem value="فرع">فرع</MenuItem>
                  <MenuItem value="كي نت">كي نت</MenuItem>
                  <MenuItem value="رابط">رابط</MenuItem>
                  <MenuItem value="كاش">كاش</MenuItem>
                </RHFSelect>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={lableSx}>تاريخ السداد</Typography>

                <YearCustomDatePicker
                  name="payment_date"
                  inputFormat="YYYY-MM-DD"
                  control={control}
                  sx={{ ...txtFieldBorderStyle }}
                  setValue={setValue}
                />
              </Grid>
            </Grid>

            {/** save Button */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={{ ...loadingButtonStyle, width: "400px", mt: "30px" }}
                loading={isLoadingCrePay}
              >
                حفظ وإضافة جديد{" "}
              </LoadingButton>
            </Box>

            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                loading={isLoadingCrePay}
                sx={loadingButtonStyle}
              >
                حفظ
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
