import React, { useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled } from "@mui/material/styles";

import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------

//* ------- Styles --------

const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

const flexBoxColumnStyle = {
  display: "flex",
  flexDirection: "column",
};

const blackTextStyle = {
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "28px",
  color: "rgba(47, 43, 44, 1)",
};

const greenTextStyle = {
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "28px",
  color: "rgba(0, 165, 142, 1)",
};

const redTextStyle = {
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "28px",
  color: "rgba(213, 26, 82, 1)",
};

//___________________________________________________________
export default function FinanceAccordion() {
  const { OppDetails } = useSelector((state) => state?.deptCollection);

  const content = [
    {
      id: "0",
      leftContent: OppDetails?.claim_value,
      rightContent: "قيمة المطالبة:",
    },
    {
      id: "1",
      leftContent: OppDetails?.amount_paid,
      rightContent: "إجمالي المسدد:",
    },
    {
      id: "2",
      leftContent: OppDetails?.reminder,
      rightContent: "الباقي:",
    },
    {
      id: "3",
      leftContent:OppDetails?.performance_fee_value,
      rightContent: "قيمة رسوم أمر الأداء:",
    },
    {
      id: "4",
      leftContent: OppDetails?.expenses_value,
      rightContent: "قيمة المصروفات:",
    },

    {
      id: "5",
      leftContent: OppDetails?.total_expenses ,
      rightContent: "إجمالي المصروفات:",
    },
    {
      id: "6",
      leftContent: OppDetails?.total_remainder,
      rightContent: "إجمالي الباقي:",
    },
  ];

  const [expanded, setIsExpanded] = useState(true);

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          onClick={() => {
            setIsExpanded(!expanded);
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          الماليات
        </AccordionSummary>
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <Grid container columnSpacing={6} paddingTop={"10px"}>
            {/**   */}
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={flexBoxColumnStyle}>
                {content?.map((c, index) => (
                  <Box
                    key={index}
                    value={c}
                    sx={{ display: "flex", alignItems: "center", pb: "10px" }}
                  >
                    <Typography sx={blackTextStyle}>
                      {c.rightContent}
                    </Typography>

                    <Typography
                      sx={{
                        ...blackTextStyle,
                        fontWeight: 400,
                        lineHeight: "24px",
                        pl: "4px",
                      }}
                    >
                      {c.leftContent}
                    </Typography>
                  </Box>
                ))}
{/* 
                <Box sx={{ display: "flex", alignItems: "center", pb: "10px" }}>
                  <Typography sx={greenTextStyle}>المبلغ المسدد:</Typography>

                  <Typography
                    sx={{
                      ...greenTextStyle,
                      fontWeight: 400,
                      lineHeight: "24px",
                      pl: "4px",
                    }}
                  >
                    {OppDetails?.amount_paid}
                  </Typography>
                </Box> */}

                {/* <Box sx={{ display: "flex", alignItems: "center", pb: "10px" }}>
                  <Typography sx={redTextStyle}>إجمالي الباقي:</Typography>

                  <Typography
                    sx={{
                      ...redTextStyle,
                      fontWeight: 400,
                      lineHeight: "24px",
                      pl: "4px",
                    }}
                  >
                    {OppDetails?.total_remainder}
                  </Typography>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomizedAccordion>
    </>
  );
}
